import makeFAIcon from "utils/makeFAIcon";

import {
  faTools,
  faHammer,
  faLock,
  faRuler,
  faSolarPanel,
} from "@fortawesome/free-solid-svg-icons";

export const ToolsIcon = makeFAIcon(faTools);
export const HammerIcon = makeFAIcon(faHammer);
export const LockIcon = makeFAIcon(faLock);
export const RulerIcon = makeFAIcon(faRuler);
export const PanelIcon = makeFAIcon(faSolarPanel);
